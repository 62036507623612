import React from 'react';
import Scrollspy from 'react-scrollspy';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scroll from '../components/Scroll';
import overLayPic from '../assets/images/overlay.png';
import { Link } from 'gatsby';

const sections = [
  { id: 'one', bg: require('../assets/images/wave.jpg') },
  { id: 'two', bg: require('../assets/images/lovetann.jpg') },
  { id: 'three', bg: require('../assets/images/lovetann.jpg') },
];
const IndexPage = () => (
  <Layout>
    <Header />

    <section id="one" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[0].bg} alt="" /> 
        </span>
        <div className="content">
          <header className="major">
            <h2>Who am I?</h2>
          </header>
          
          <p>I'm a full-stack developer residing in Kristiansand, Norway</p>
          <p>My main languages are C# and JavaScript, but I am experienced in a wide variety of technologies</p>
          <p> I'm currently employed as a senior consultant at <a href="https://www.webstep.no"> Webstep AS</a>  </p>
          
        </div>
        <Scroll type="id" element="three">
          <a href="#three" className="xgoto-next" style={{ fontWeight: 'bold', fontSize: '22px'}}>
          <span style={{transform: 'rotate(90deg)', display: 'inline-block'}}>  &gt;</span>
          </a>
        </Scroll>
      </div>
    </section>

     {/* <section id="two" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[1].bg} alt="" />
        </span>
        <div className="content">
          <header className="major">
            <h2>Stuff I do</h2>
          </header>
          <p>
            Consequat sed ultricies rutrum. Sed adipiscing eu amet interdum
            lorem blandit vis ac commodo aliquet vulputate.
          </p>
          <ul className="icons-grid">
            <li>
              <span className="icon major fa-camera-retro" />
              <h3>Photography</h3>
            </li>
            <li>
              <span className="icon major fa-pencil" />
              <h3>Blogging</h3>
            </li>
            <li>
              <span className="icon major fa-code" />
              <h3>Web Development</h3>
            </li>
            <li>
              <span className="icon major fa-coffee" />
              <h3>Sipping Coffee</h3>
            </li>
          </ul>
        </div>
        <Scroll type="id" element="three">
          <a href="#three" className="goto-next ">
            Next
          </a>
        </Scroll>
      </div>
    </section>  */}


    <section id="three" className="main special">
      <div className="container">
        <span className="image fit primary">
          <img src={sections[2].bg} alt="" /> 
        </span>
        <div className="content">
          <header className="major">
            <h2>Interests</h2>
          </header>
          <p>I have experience from different sectors and different roles in companies of various sizes</p>
          <p>I'm interested in sharing my knowledge (and learning more) with people who are interested in improving the world</p>
        </div>
        <Scroll type="id" element="footer">
           <a href="#footer" className="xgoto-next" style={{ fontWeight: 'bold', fontSize: '22px'}}>
          <span style={{transform: 'rotate(90deg)', display: 'inline-block'}}>  &gt;</span>
          </a>
        </Scroll>
      </div>
    </section>

    <section id="footer">
      <div className="container">
        <header className="major">
          <h2>Get in touch</h2>
        </header>
        <p><a href="mailto:hello@tknilsen.no">hello@tknilsen.no</a></p>
        {/* <form method="post" action="#">
          <div className="row gtr-uniform">
            <div className="col-6 col-12-xsmall">
              <input type="text" name="name" id="name" placeholder="Name" />
            </div>
            <div className="col-6 col-12-xsmall">
              <input type="email" name="email" id="email" placeholder="Email" />
            </div>
            <div className="col-12">
              <textarea
                name="message"
                id="message"
                placeholder="Message"
                rows="4"
              />
            </div>
            <div className="col-12">
              <ul className="actions special">
                <li>
                  <input
                    type="submit"
                    value="Send Message"
                    className="primary"
                  />
                </li>
              </ul>
            </div>
          </div>
        </form> */}
        {/* <Link to="/Elements"> Check out Elements page</Link> */}
      </div>
      <Footer />
    </section>

    <Scrollspy
      items={sections.map(s => s.id)}
      currentClassName="active"
      offset={50}
      componentTag={'div'}
    >
      {sections.map(s => {
        return (
          <div
            key={s.id}
            className="main-bg"
            id={`${s.id}-bg`}
            style={{ backgroundImage: `url(${overLayPic}), url(${s.bg})` }}
          />
        );
      })}
    </Scrollspy>
  </Layout>
);

export default IndexPage;
